import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import colors from 'styles/vars.scss';
import css from './Arrow.scss';

const Arrow = ({
  className,
  svgAnimate,
  contentAnimate,
  style = {},
  color = colors.black,
  direction = 'bottom',
  rerenderOn = null
}) => {
  const [fillColor, setFillColor] = useState();
  useEffect(() => {
    setFillColor(color);
  }, [color, rerenderOn]);
  const defaultStyle = {
    fill: fillColor
  };
  const angle = (() => {
    switch (direction) {
      case 'left':
        return -90;
      case 'right':
        return 90;
      case 'top':
        return 0;
      case 'bottom':
      default:
        return 180;
    }
  })();
  const transform = [`rotate(${angle}deg)`, style.transform || ''].join(' ');
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.41 12.12"
      className={[className, css.Arrow].join(' ')}
      initial={{
        ...defaultStyle
      }}
      animate={svgAnimate}
      style={{
        ...style,
        fill: fillColor,
        transform,
      }}
      title={JSON.stringify(rerenderOn)}
    >
      <motion.polygon
        className="cls-1"
        points="10.71 0 0 10.71 1.41 12.12 10.71 2.83 20 12.12 21.41 10.71 10.71 0"
        initial={{
          ...defaultStyle
        }}
        animate={contentAnimate}
      />
    </motion.svg>
  );
};

export default Arrow;
