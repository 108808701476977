import Arrow from 'components/Layout/Arrow';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import css from './Footer.scss';

const Footer = ({ className = '', onClick = () => {} }) => {
  const router = useRouter();
  const matchPattern = new RegExp(`sommaire/?`);
  const isSummary = !!router.pathname.match(matchPattern);
  return (
    <div
      className={[className, css.Footer].join(' ')}
    >
      <div
        className={css.Wrapper}
      >
        {(!isSummary && router.pathname !== '/') && (
          <Link href="/sommaire">
            <a
              className={css.BackToSummary}
              onClick={() => {
                onClick();
              }}
            >
              <Arrow
                direction={'left'}
                style={{
                  transform: 'scale(0.5)',
                  marginTop: '-2px'
                }}
              />
              Retour au sommaire
            </a>
          </Link>
        )}
        Une question ?
        Un problème ?
        &nbsp;
        <Link href="mailto:sncf.communicationrh@sncf.fr">
          <a>
            Contacter l’agence communication RH
          </a>
        </Link>
        &nbsp;-&nbsp;
        <Link href="/mentions-legales">
          <a>
            Mentions légales
          </a>
        </Link>
        &nbsp;-&nbsp;
        <Link href="/documents/cgu.pdf">
          <a
            target={'_blank'}
          >
            CGU
          </a>
        </Link>
        &nbsp;-&nbsp;
        <Link href="/cookies">
          <a>
            Cookies
          </a>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
